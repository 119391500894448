<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 证书导入 </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入手机号" v-model="mobile" />
        <a-select style="width: 400px" v-model="certId" placeholder="证书名称">
          <a-select-option
            :value="item.tempId"
            v-for="item in tempList"
            :key="item.tempId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn" @click="onAdd">新建</a-button>
        <a-button type="primary" class="btn down" @click="downDemo">下载模板</a-button>
        <a-upload
          class="btn"
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
        >
          <a-button type="primary">批量导入</a-button>
        </a-upload>
        <a-button type="primary" :loading="createdVisible" @click="createCertificate">生成证书</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="mobile" slot-scope="text, item">
        {{ item.mobile ? $encryption('user:infomation:sensitivequery','phone',item.mobile) : "--" }}
        </template>
        <template slot="idcard" slot-scope="text, item">
        {{ item.idcard ? $encryption('user:infomation:sensitivequery','idCard',item.idcard) : "--" }}
        </template>

        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <div v-if="row.url" style="position: relative; overflow: hidden">
              <a>查看证书</a>
              <viewer>
                <img
                  style="
                    position: absolute;
                    top: -10px;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                  "
                  class="inneer_img"
                  :src="row.url"
                />
              </viewer>
            </div>
            |

            <div>
              <a-popconfirm
                title="是否删除证书？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteitem(row.id)"
              >
                <a href="#">删除</a>
              </a-popconfirm>
            </div>
          </div>
        </template>
      </a-table>
    </div>
    
    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
    scopedSlots: { customRender: "idcard" },
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "certifName",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "发证时间",
    align: "center",
    dataIndex: "getcertifTime",
  },

  {
    title: "获证来源",
    align: "center",
    dataIndex: "certifSource",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "发证编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "发证日期",
    align: "center",
    dataIndex: "getcertifTime",
  }
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox,LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columnsss,
      certId: undefined, //标签关键字搜索
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      mobile: "",
      workUnitId: null,
      tempList: [], // 正确数据
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据

      createdVisible: false, // 批量生成证书按钮loading
    };
  },
  // 事件处理器
  methods: {
    // 批量生成证书
    createCertificate() {
      // 五分钟之内不能重复生成
      if(localStorage.getItem('createCertificate') == 'true') {
        return this.$message.error('由于证书生成较慢,请五分钟后查看是否生成成功!')
      }
      this.createdVisible = true
      this.$ajax({
        url: "/hxclass-management/importCertificate/create",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          localStorage.setItem('createCertificate',true)
          this.interVal = setTimeout(()=>{
            localStorage.setItem('createCertificate',false)
          },300000)
          this.$message.success('证书正在生成请您耐心等待！');
          this.createdVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 下载模板
    downDemo() {
      window.open(
        "https://cos.hxclass.cn/prod/uploaded/excel/certificatesImport.xls"
      );
    },

    modelhandleCancel() {
      this.importVisible = false;
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/importCertificate/excel",
        method: "post",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.ok
          this.failureList = res.data.err
          this.importVisible = true
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 导入正确数据
    importSuccessList() {
      this.$ajax({
        url: "/hxclass-management/importCertificate/addList",
        method: "POST",
        params: {
          name: JSON.stringify(this.successList)
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 下载错误数据
    downloadExcel() {
      this.$message.error('暂无此功能！');
    },

    onAdd() {
      this.$router.push("/admin/Certificate/newCertificate");
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // tab切换
    callback(e) {},
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.getData();
    },
    // 获取数据
    getData() {
      this.$ajax({
        url: "/hxclass-management/importCertificate/pageList",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          mobile: this.mobile,
          certId: this.certId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 集合列表
    getTempid() {
      this.$ajax({
        url: "/hxclass-management/importCertificate/select/certif-list",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tempList = res.data;
        }
      });
    },
    // 删除
    deleteitem(e) {
      this.$ajax({
        url: "/hxclass-management/importCertificate/deleteCertif?id=" + e,
        method: "DELETE",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getData();
          this.$message.success(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取数据
    this.getData();
    this.getTempid();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.down{
  margin-left: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.modal_box {
  display: flex;
  align-items: center;
  .tag {
    flex-shrink: 0;
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  // /deep/.ant-table {
  //   border: 1px solid #eeeeee;
  //   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  // }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.left {
  margin-left: 16px;
}
.btn_router_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
